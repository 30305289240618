<template>
  <div class="popup-wrap">
    <div class="form-cont">
      <div class="top">
        <span v-if="title" class="title">{{ title }}</span>
        <slot v-else name="title"></slot>
        <span @click="handleClose"><svg-icon icon-class="close"></svg-icon></span>
      </div>
      <slot name="form"></slot>
      <div class="bottom" v-if="isBtnShow">
        <span @click="handleOk">确定</span>
        <span @click="handleClose">取消</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    isBtnShow: Boolean
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("ok");
    }
  }
};
</script>

<style lang="less" scoped>
.popup-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: rgba(29, 33, 41, 0.45);
}

.form-cont {
  position: relative;
  top: 40%;
  left: calc(50% + 130px);
  width: 444px;
  padding: 24px 26px 30px;
  background: #fff;
  box-shadow: 0 12px 48px 16px rgba(0, 0, 0, 0.03), 0 9px 28px 0 rgba(0, 0, 0, 0.05),
    0 6px 16px -8px rgba(0, 0, 0, 0.08);
  transform: translate(-50%, -50%);
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    .title {
      color: #111c34;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
    }
    .svg-icon {
      font-size: 12px;
      cursor: pointer;
    }
  }
  .bottom {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 36px;
    > span {
      display: block;
      width: 66px;
      height: 32px;
      margin-left: 10px;
      color: #13203c;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
    }
    :first-child {
      color: #545beb;
    }
  }
}
</style>
