import request from "tsl-axios";

/**
 * 上传文件
 * @returns {*}
 */
export function uploadFile(formData) {
  return request
    .post("/file/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then((response) => {
      return response;
    });
}
