<template>
  <div class="usercont" v-if="user">
    <div class="user-title">基本信息</div>
    <div class="user-avatar">
      <img v-if="user.headImgUrl" :src="user.headImgUrl" alt="" />
      {{ user.headImgUrl ? "" : avatarStr }}
      <div class="upload-mask" @click="uploadAvatar">上传</div>
      <input type="file" ref="fileUpload" @change="getFile" class="file-upload" />
    </div>
    <div class="info-cell">
      <p>用户账号</p>
      <span>{{ user.userAccount }}</span>
    </div>
    <div class="divider" />
    <div class="info-cell">
      <p>用户昵称</p>
      <span>{{ user.nickName || "未设置" }}</span>
      <span @click="isPopupShow = true"><svg-icon icon-class="edit"></svg-icon></span>
    </div>
    <div class="divider" />
    <div class="info-cell">
      <p>注册时间</p>
      <span>{{ user.createTime }}</span>
    </div>

    <popup-form
      v-show="isPopupShow"
      title="修改用户昵称"
      :isBtnShow="true"
      @close="handleClose"
      @ok="handleOk"
    >
      <template #form>
        <div class="form">
          <div class="input-cell">
            <span class="input-label">昵称</span>
            <input type="text" class="user-input" placeholder="请输入用户昵称" v-model="username" />
            <div class="errtip" v-show="errMsg">{{ errMsg }}</div>
          </div>
        </div>
      </template>
    </popup-form>
  </div>
</template>

<script>
import PopupForm from "@/components/popup-form/Index.vue";
import userMixin from "@/mixins/user-mixin.js";
import { pattern_nickname } from "@/tool/pattern.js";
import { changeUserinfo } from "@/api/portal.js";
import { uploadFile } from "@/api/upload.js";

export default {
  components: {
    PopupForm
  },
  mixins: [userMixin],
  data() {
    return {
      isPopupShow: false,
      username: "",
      errMsg: "",
      avatarImg: ""
    };
  },
  methods: {
    handleClose() {
      this.isPopupShow = false;
      this.username = "";
      this.errMsg = "";
    },
    handleOk() {
      const valid = pattern_nickname.test(this.username);
      if (!valid) {
        this.errMsg = "用户昵称不符合规则（长度10，允许汉字、字母和数字）";
        return;
      }
      this.updateUserinfo(1, this.username, "");
      this.isPopupShow = false;
      this.username = "";
      this.errMsg = "";
    },
    uploadAvatar() {
      this.$refs.fileUpload.dispatchEvent(new MouseEvent("click"));
    },
    async getFile() {
      const file = this.$refs.fileUpload.files[0];
      const formData = new window.FormData();
      formData.append("file", file);
      const res = await uploadFile(formData);
      this.avatarImg = res.data;
      this.updateUserinfo(2, "", this.avatarImg);
    },
    // type 1-nickName 2-headImgUrl
    async updateUserinfo(type, nickName, headImgUrl) {
      let res;
      if (type === 1 && nickName) {
        res = await changeUserinfo({
          id: this.user.id,
          nickName
        });
      } else if (type === 2 && headImgUrl) {
        res = await changeUserinfo({
          id: this.user.id,
          headImgUrl
        });
      }
      if (!res.success) {
        this.$message.error(res.message);
        return;
      }
      this.$store.dispatch("user/getUserInfo");
    }
  }
};
</script>

<style lang="less" scoped>
.user-avatar {
  position: relative;
  width: 60px;
  height: 60px;
  margin-bottom: 24px;
  overflow: hidden;
  color: #fff;
  font-weight: 500;
  font-size: 25px;
  font-family: Roboto-Medium, Roboto;
  line-height: 60px;
  text-align: center;
  background-color: #9baed3;
  border-radius: 30px;
  cursor: pointer;
  > img {
    width: 60px;
    height: 60px;
    margin-top: -7px;
  }
  .upload-mask {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 99;
    width: 60px;
    height: 60px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 60px;
    text-align: center;
    background-color: #1d2129;
    opacity: 0.7;
    transition: all 0.3s;
  }
  &:hover {
    .upload-mask {
      top: 0;
    }
  }
}
.divider {
  width: 100%;
  height: 0;
  margin: 20px 0;
  border-top: 1px solid #e5e6eb;
}
.input-label {
  width: 32px;
}
.user-input {
  width: calc(100% - 32px);
}
.errtip {
  left: 42px;
}
.file-upload {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
</style>
